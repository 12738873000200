import React from "react"
import * as styles from './../SideTabsContainer.module.scss'


const SideTabContent = ({data, selectedTab, idx, classes, ...args}) => {

    return(  
        <article className={`${data.id} ${selectedTab === data.id? 'active' : ''}`}>
            <span className={`${styles.sideTab_graphic}`}><img src={data.img.url} alt={data.img.alt} width={data.img.size}/></span>
            <p className={`${styles.sideTab_info}`}>
                {data.content}
            </p>
        </article>
    )
}

export default SideTabContent 