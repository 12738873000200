import React, { Fragment } from 'react'
import { navigate } from 'gatsby'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import SideTabsContainer from '../components/UI/Containers/SideTabsContainer/SideTabsContainer'
import CollapsibleBox from '../components/UI/Containers/CollapsibleBox/CollapsibleBox'

import './../styles/pageSpecific/careers.scss'
import { Icon } from '@iconify/react'

import MedicalLogo from './../images/benefits/uhc-logo.png'
import TricareLogo from './../images/benefits/SelmanCo-logo.png'
import GuardianLogo from './../images/benefits/guardian-logo.png'
import FSAlogo from './../images/benefits/fsa-icon.png'
import HSAlogo from './../images/benefits/ambg-logo.png'
import FidelityLogo from './../images/benefits/fidelity-logo.png'

const isBrowser = typeof window !== 'undefined'

const BenefitsPage = ({ location }) => {
  let benefitsType
  if (isBrowser) {
    if (!location.state) {
      navigate('/careers', { replace: true })
    } else {
      benefitsType =
        location.state.type === 'professional' ? 'professional' : 'technical'
    }
  }

  const pageInfo = {
    pageType: 'interior',
    title1: 'Employee',
    title2: 'Benefits',
    breadcrumbs: ['Careers'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer id="introBenefits" color="white" type="contained">
          <p className={`basicText narrowContentCol`}>
            Precise Systems offers an environment that promotes work/life
            balance and strong bonds with co-workers. Our excellent benefits
            packages and growth opportunities far exceed those of other
            companies. New hires are eligible for benefits the 1st day of the
            month following their employment start date.
          </p>
          <SectionHeader
            color="navy"
            alignment="center"
            size="med"
            trim={true}
            tag={`  `}
          >
            Health &amp; <span>Welfare</span>
          </SectionHeader>
          <div className={`narrowContentCol`}>
            <SideTabsContainer
              sideTabsData={[
                {
                  title: 'Medical Plans',
                  id: 'medical',
                  content:
                    'At Precise, we recognize that providing high-quality health coverage gives our employees priceless peace of mind. Precise offers medical insurance plans through United Healthcare; employees have the flexibility to choose the option that works best for their family’s health care usage.',
                  img: {
                    url: MedicalLogo,
                    alt: 'United Healthcare Logo',
                    size: '100%',
                  },
                },
                {
                  title: 'TRICARE Supplement',
                  id: 'tricare',
                  content:
                    'Precise offers an optional TRICARE Supplement through Selman &amp; Company. The Supplement coordinates with TRICARE to virtually eliminate out-of-pocket costs to the insured for health services by design.',
                  img: {
                    url: TricareLogo,
                    alt: 'Selman & Co Logo',
                    size: '100%',
                  },
                },
                {
                  title: 'Vision Plan',
                  id: 'vision',
                  content:
                    'Vision coverage is provided through Guardian. These plans feature professional vision services, including routine eye examinations, and provides an allowance for eyeglasses and contact lenses. Precise offers two vision plans which allow our employees to choose the option best suited to their needs.',
                  img: {
                    url: GuardianLogo,
                    alt: 'Guardian Insurance Logo',
                    size: '100%',
                  },
                },
                {
                  title: 'Dental Plan',
                  id: 'dental',
                  content:
                    'Guardian Dental provides dental insurance coverage. Precise offers two dental plans which allow our employees to choose the option best suited to their needs.',
                  img: {
                    url: GuardianLogo,
                    alt: 'Guardian Insurance Logo',
                    size: '100%',
                  },
                },
                {
                  title: 'Flexible Spending Accounts',
                  id: 'fsa',
                  content:
                    'Flexible Spending Accounts (FSAs) offer the ability to pay for certain qualified benefits on a pre-tax basis. Paying for those benefits with pre-tax dollars reduces the amount paid in taxes and increases take-home pay. FSAs are available for: Dependent Care, Flex Spending Account (FSA), Limited Purpose Flexible Spending Account (LPFSA), and Transportation Reimbursement.',
                  img: {
                    url: FSAlogo,
                    alt: 'FSA Icon',
                    size: '86px',
                  },
                },
                {
                  title: 'Health Savings Accounts',
                  id: 'hsa',
                  content:
                    'Health Savings Accounts (HSA) are tax-advantaged member-owned accounts that let employees save pre-tax1 dollars for future qualified medical expenses.  HSAs are available exclusively to those with a qualifying health plan.',
                  img: {
                    url: HSAlogo,
                    alt: 'American Benefits Group Logo',
                    size: '100%',
                  },
                },
              ]}
            />
          </div>
        </SectionContainer>

        {/* Income Protection */}
        <SectionContainer id="benefitsProtection" color="navy" type="fullWidth">
          <SectionHeader
            color="white"
            alignment="center"
            size="med"
            trim={true}
            tag={`  `}
          >
            Income <span>Protection</span>
          </SectionHeader>
          <p className={`subHead_lg narrowContentCol`}>
            {`${
              benefitsType === 'professional'
                ? 'Precise offers several company paid benefits to protect your income'
                : 'Precise offers several benefits to protect your income'
            }  `}
          </p>
          <section
            id="benefitsCheckMarks"
            className={`inlineChildren_mdMobile narrowContentCol`}
          >
            <div className="stackedImageText">
              <span className="stacked_graphic">
                <Icon
                  icon="bx:bx-check-circle"
                  className={'checkMark'}
                  inline={false}
                  width="50"
                  height="50"
                  vAlign="center"
                />
              </span>
              <p className="stacked_text">
                Short-Term <br />
                Disability
              </p>
            </div>
            <div className="stackedImageText">
              <span className="stacked_graphic">
                <Icon
                  icon="bx:bx-check-circle"
                  className={'checkMark'}
                  inline={false}
                  width="50"
                  height="50"
                  vAlign="center"
                />
              </span>
              <p className="stacked_text">
                Long-Term <br />
                Disability
              </p>
            </div>
            <div className="stackedImageText">
              <span className="stacked_graphic">
                <Icon
                  icon="bx:bx-check-circle"
                  className={'checkMark'}
                  inline={false}
                  width="50"
                  height="50"
                  vAlign="center"
                />
              </span>
              <p className="stacked_text">Group Term Life Insurance</p>
            </div>
            <div className="stackedImageText">
              <span className="stacked_graphic">
                <Icon
                  icon="bx:bx-check-circle"
                  className={'checkMark'}
                  inline={false}
                  width="50"
                  height="50"
                  vAlign="center"
                />
              </span>
              <p className="stacked_text">Accidental Death or Dismemberment</p>
            </div>
          </section>
          <div className="topDivider narrowContentCol"></div>
          <CollapsibleBox
            id="lifeInsurance"
            header="Supplemental Life Insurance"
            tag=""
            footnote=""
            classes={['narrowContentCol', 'white']}
          >
            <Fragment>
              <p>
                Eligible employees and their dependents have the option of
                enrolling in supplemental life insurance. Employees are eligible
                for voluntary life coverage above the Precise provided benefit
                in increments of $10,000 with a guarantee issue of $50,000 if
                enrolled within the first 30 days of employment. Spousal life
                coverage up to $100,000 and dependent life coverage up to
                $10,000 are also available.
              </p>
            </Fragment>
          </CollapsibleBox>
          <CollapsibleBox
            id="employeeLeave"
            header="Employee Managed Leave "
            tag=""
            footnote=""
            classes={['narrowContentCol', 'white']}
          >
            <Fragment>
              <p>
                {benefitsType === 'professional'
                  ? 'Precise Systems employees enjoy a flexible work schedule that allows for work-life balance. Employees manage their time off with an accrual-based system.'
                  : 'Precise Systems employees enjoy a flexible work schedule that allows for work-life balance. Employees manage their time off with annual leave allocations based on tenure of the contract in which they support.'}
              </p>
            </Fragment>
          </CollapsibleBox>
          {/* {benefitsType === 'technical' ? 
                             <CollapsibleBox 
                                    id='sickLeave'
                                    header='Sick Leave'
                                    tag=''
                                    footnote=''
                                    classes={['narrowContentCol', 'white']}>
                                        <Fragment>
                                            <p>Is offered in accordance with federal, state and local requirements. Precise recognizes that providing paid sick leave not only supports our employees but also improves the health of the workplace. </p>
                                        </Fragment>
                                </CollapsibleBox> : ''} */}

          <CollapsibleBox
            id="eap"
            header="Employee Assistance Program (EAP)"
            tag=""
            footnote=""
            classes={['narrowContentCol', 'white']}
          >
            <Fragment>
              <p>
                Precise Systems automatically enrolls all eligible employees in
                the company paid Employee Assistance Program (EAP). Employees
                may contact this free, confidential resource for help with
                counseling for personal issues; information, referrals, and
                resources for work-life needs; legal information, resources, and
                consultations; financial information, resources, and tools;
                online will preparation; support for expectant and new parents;
                and other areas related to work-life balance.
              </p>
            </Fragment>
          </CollapsibleBox>
          <CollapsibleBox
            id="performanceMangement"
            header="Performance Management"
            tag=""
            footnote=""
            classes={['narrowContentCol', 'white']}
          >
            <Fragment>
              <p>
                Performance management is important to Precise Systems. Our
                Performance Management System is an ongoing process of
                communication between a supervisor and an employee that occurs
                throughout the year. The communication process includes
                clarifying expectations, setting objectives, identifying goals,
                providing feedback, and reviewing results.
              </p>
            </Fragment>
          </CollapsibleBox>
          {/* <CollapsibleBox 
                        id='entertainmentBenefit'
                        header='Entertainment Benefit '
                        tag=''
                        footnote=''
                        classes={['narrowContentCol', 'white']}>
                            <Fragment>
                                <p>Plum Benefits is a great resource for discounted and hard-to-get tickets for Broadway shows, sporting events, concerts, museums, family shows, and attractions, as well as vacation packages! As a Precise employee, membership in Plum Benefits is free and convenient to access entertainment.</p>
                            </Fragment>
                    </CollapsibleBox> */}
        </SectionContainer>

        {/* Retirement Planning */}
        <SectionContainer id="benefitsRetirment" color="gray" type="fullWidth">
          <SectionHeader
            color="navy"
            alignment="center"
            size="med"
            trim={true}
            tag={`  `}
          >
            Retirement <span>Planning</span>
          </SectionHeader>

          <p className={`basicText thinText narrowContentCol`}>
            <h3>
              401K and Roth with{' '}
              <img src={FidelityLogo} alt="Fidelity" width="80" />
            </h3>
            There are many great benefits to being part of the Precise Systems
            401(k) Plan. Our workplace savings plan makes it easy, convenient,
            and affordable to accumulate the money you will need for retirement.
            Our comprehensive retirement package is offered through Fidelity
            Investments. Employees are eligible for participation in either the
            traditional 401(k) or Roth 401(k) plans the first month after 30
            days of service. A wide variety of investment options are available
            through Fidelity Investments. By offering both traditional and Roth
            401(k) options, employees may determine the investment strategy best
            suited to their retirement needs.
          </p>
          <br />
          <p className={`basicText thinText narrowContentCol`}>
            Employees elect a salary percentage to defer to either the
            traditional pre-tax 401(k) or the post-tax Roth 401(k). The Roth
            401(k) allows employees to contribute after-tax dollars and then
            withdraw tax-free dollars at retirement. Please consult a tax
            professional to determine which contribution strategy is best for
            your circumstances.
          </p>
        </SectionContainer>

        {/* Employee Enrichment */}
        {benefitsType === 'professional' ? (
          <SectionContainer
            id="benefitsEnrichment"
            color="white"
            type="contained"
          >
            <SectionHeader
              color="navy"
              alignment="center"
              size="med"
              trim={true}
              tag={`  `}
            >
              Employee <span>Enrichment</span>
            </SectionHeader>
            <CollapsibleBox
              id="education"
              header="Education Assistance"
              tag=""
              footnote=""
              classes={['narrowContentCol']}
            >
              <Fragment>
                <p>
                  Employees are encouraged to continue their education through
                  our educational assistance plan.
                </p>
              </Fragment>
            </CollapsibleBox>
            <CollapsibleBox
              id="professionalDev"
              header="Professional Development "
              tag=""
              footnote=""
              classes={['narrowContentCol']}
            >
              <Fragment>
                <p>
                  Precise Systems encourages all employees to seek professional
                  training to continue to expand their knowledge and skills.
                  Requests are approved based on job-relatedness, business
                  needs, and other related factors.
                </p>
              </Fragment>
            </CollapsibleBox>
            <CollapsibleBox
              id="professionalAssoc"
              header="Professional Associations"
              tag=""
              footnote=""
              classes={['narrowContentCol']}
            >
              <Fragment>
                <p>
                  Precise Systems feels that it is essential for our employees
                  to stay networked within their professional fields and offers
                  a yearly subsidy to employees towards membership in a
                  business-related organization.
                </p>
              </Fragment>
            </CollapsibleBox>
          </SectionContainer>
        ) : (
          ''
        )}

        {/* Compensation Incentives */}
        <SectionContainer
          id="benefitsIncentives"
          color="white"
          type="contained"
        >
          <SectionHeader
            color="navy"
            alignment="center"
            size="med"
            trim={true}
            tag={`  `}
          >
            Compensation <span>Incentives</span>
          </SectionHeader>
          <CollapsibleBox
            id="compensationProgram"
            header="Compensation Program"
            tag=""
            footnote=""
            classes={['narrowContentCol']}
          >
            <Fragment>
              <p>
                Precise Systems compensation program ensures consistency,
                fairness, and equitability throughout the company. It allows
                salary decisions to be based upon appropriate qualifications,
                contributions, performance, and equity and budget
                considerations. Encourages and rewards excellent performance
                with merit increases, whenever possible. The program also
                provides salary increases, when appropriate, within available
                funding, and motivates employees by demonstrating the link
                between performance and pay.
              </p>
            </Fragment>
          </CollapsibleBox>
          {benefitsType === 'professional' ? (
            <CollapsibleBox
              id="referralBonus"
              header="Employee Referral Bonus"
              tag=""
              footnote=""
              classes={['narrowContentCol']}
            >
              <Fragment>
                <p>
                  Precise is a great place to work. Precise encourages our
                  employees to seek out and refer candidates for employment to
                  Precise Systems and reward them for connecting the company to
                  talent.
                </p>
              </Fragment>
            </CollapsibleBox>
          ) : (
            ''
          )}
          <p className="footnote simple narrowContentCol">
            * DISCLAIMER: Please note that these are the Precise Systems Core
            Benefit offerings. Employee benefits and eligibility will be
            determined based on State and Local laws. The information contained
            within is a summary and is not intended to be a full or complete
            description of the benefit plans offered by Precise Systems. The
            specific provisions of contracts with the carriers will be
            controlling. At its discretion, Precise Systems reserves the right
            to amend, suspend or discontinue (totally or partially) any of its
            benefit plans at any time. *
          </p>
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default BenefitsPage
