import React, {useState, useCallback} from "react"
import './CollapsibleBox.scss'
import { Icon } from '@iconify/react';


const CollapsibleBox = ({id, header, tag=null, children, footnote=null, classes, ...args}) => {
    const [showInfo, setShowInfo] =  useState(false)

    const closeCollapsible = useCallback((e) => {
        if(e.target.matches(`#${id} header`) || !e.target.closest(`#${id}_details`)){
            setShowInfo(false)
            document.removeEventListener("click", closeCollapsible);
        }
      
       }, [id]);

    const toggleInfo = useCallback( e => {
       if (showInfo) {  
            closeCollapsible(e);
            return;
        }
        
        let actives = document.querySelectorAll(`article.showCollapsible button:not(#${id} button)`);
        if(actives){
            for (let i = 0; i < actives.length; ++i) {
                actives[i].click();
            };
        } 
 
        setShowInfo(showInfo => !showInfo);
        e.stopPropagation();
        document.addEventListener("click", closeCollapsible);
    }, [id, closeCollapsible, showInfo] )

    return(  
        <article id={id} className={`${classes ? classes.join(' ') : ''} collapsibleBox ${showInfo ? `showCollapsible` : ''}  `} {...args}>
            <header className={`collapsibleBox_header`}>
                <button tabIndex='0' onClick={toggleInfo} aria-controls={`${id}_details`} aria-expanded={showInfo}>{header}<Icon className={`dropdownArrow__icon`} icon="eva:arrow-ios-forward-fill" inline={true} width={20}/></button>
            </header>
            {tag ? <p className={`collapsibleBox_tag`}><em><strong>{tag}</strong></em></p> : ''}
            {children ? <section id={`${id}_details`} className={`collapsibleBox_details`}>
                            {children}
                        </section> : '' }
            {footnote ? <div className={`collapsibleBox_footnote footnote`}>{footnote}</div> : ''}
        </article>
    )
}

export default CollapsibleBox 