import React, { useState, useCallback } from "react"
import * as styles from './SideTabsContainer.module.scss'

import SideTabContent from './SideTabContent/SideTabContent'

const SideTabsContainer = ({sideTabsData, classes=null, ...args}) => {
    //data Template
    // sideTabsData[{
    //   title: '',
    //   id:'',
    //   content: '',
    //   img: {
    //        url:'',
    //        alt:'',
    //        size:''
    //  }
    // }]
    const [ selectedTab, setSelectedTab ] =  useState(sideTabsData[0].id)

    const handleTabClick = useCallback((e) => {
        setSelectedTab( prev => {
            if(prev === e.target.id){
                return prev
            }else{
                return e.target.id
            }
        }) 
    }, []);
    let tabContentsList = sideTabsData.map((sideTab, idx) => {
        return <SideTabContent key={sideTab.id} data={sideTab} selectedTab={selectedTab} idx={idx} />
    })
    let tabButtonsList = sideTabsData.map((sideTab, idx) => {
        return <li key={idx}><button onClick={handleTabClick} onKeyPress={handleTabClick} id={sideTab.id} className={selectedTab === sideTab.id ? 'active' : ''}>{sideTab.title}</button></li>
    }) 
    
    return(  
        <article className={`${styles.sideTabs_container} health-benefits ${classes ? classes.join(' ') : ''}`} {...args}>
            <section className={`${styles.sideTabs_tabs}`}>
                <ul>
                    {tabButtonsList}
                </ul>
            </section>
            <section className={`${styles.sideTabs_content}`}>
                {tabContentsList}
            </section>
        </article>
    )
}

export default SideTabsContainer 